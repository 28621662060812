import React from 'react';
import { marked } from 'marked';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import style from './contact-text.module.css';

const ContactText = () => {
  const { contentfulContact } = useStaticQuery(graphql`
    {
      contentfulContact {
        socials {
          socials
        }
        biography {
          biography
        }
        image {
          fixed(height: 500, quality: 65) {
            ...GatsbyContentfulFixed
            width
            height
          }
        }
      }
    }
  `);

  const { fixed } = contentfulContact.image;
  const sharedStyles = { height: '100%', width: 'auto', pointerEvents: 'none' };
  const imageContainerStyles = { ...sharedStyles, marginTop: '4px' };
  const imageStyles = {
    ...sharedStyles,
    left: 'auto',
    right: '0',
    aspectRatio: `${fixed.width} / ${fixed.height}`,
  };

  return (
    <div className={style.grid}>
      <div
        className={style.socials}
        dangerouslySetInnerHTML={{
          __html: marked.parse(contentfulContact.socials.socials),
        }}
      />
      <Img
        style={imageContainerStyles}
        imgStyle={imageStyles}
        fixed={fixed}
        loading="eager"
      />
      <div
        className={style.biography}
        dangerouslySetInnerHTML={{
          __html: marked.parse(contentfulContact.biography.biography),
        }}
      />
    </div>
  );
};

export default ContactText;
